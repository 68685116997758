.App {
  text-align: left;
}

.App-logo {
  animation: genie-float infinite 20s linear;
  height: 70vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-flyIN {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes genie-float {
  0%   {transform: translateY(0)}
  25%  {transform: translateY(-25px)}
  75%  {transform: translateY(25px)}
  100% {transform: translateY(0)}
}
@keyframes hatTail-flame {
  0%   {transform: scale(1, 1)}
  50%  {transform: scale(1, 1.1)}
  100% {transform: scale(1, 1)}
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
